<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-table
      ref="refFlowListTable"
      :items="fetchFlows"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: Flux -->
      <template #cell(flux)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="`${publicPath}` + `images/flux/` + `${data.item.logo}`"
              :text="avatarText(data.item.name)"
              :to="{
                name: 'flow-detail',
                params: { id: data.item.ID },
                query: { flowCode: data.item.job_name },
              }"
            />
          </template>
          <b-link
            :to="{
              name: 'flow-detail',
              params: { id: data.item.ID },
              query: { flowCode: data.item.job_name },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted"
            >origin: {{ data.item.flow_origin }} - job:
            {{ data.item.job_name }}</small
          >
        </b-media>
      </template>

      <!-- Column: Annonce -->
      <template #cell(annonce)="data">
        <span class="text-nowrap">
          {{ data.item.type_offers }}
        </span>
      </template>

      <!-- Column: Format -->
      <template #cell(format)="data">
        <span class="text-nowrap">
          {{ data.item.format }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(statut)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.status)}`"
        >
          {{ data.item.status }}
        </b-badge>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalFlows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { resolveAvatarLink } from "@core/utils/utils";
import useFlowsList from "./useFlowList";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  setup() {
    const {
      fetchFlows,
      tableColumns,
      perPage,
      currentPage,
      totalFlows,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refFlowListTable,

      refetchData,

      resolveStatusVariant,
    } = useFlowsList();

    return {
      fetchFlows,
      tableColumns,
      perPage,
      currentPage,
      totalFlows,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refFlowListTable,

      refetchData,

      avatarText,
      resolveStatusVariant,
      resolveAvatarLink,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.flow-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
