var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-table',{ref:"refFlowListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchFlows,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(flux)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":"" + _vm.publicPath + "images/flux/" + "" + (data.item.logo),"text":_vm.avatarText(data.item.name),"to":{
              name: 'flow-detail',
              params: { id: data.item.ID },
              query: { flowCode: data.item.job_name },
            }}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
            name: 'flow-detail',
            params: { id: data.item.ID },
            query: { flowCode: data.item.job_name },
          }}},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v("origin: "+_vm._s(data.item.flow_origin)+" - job: "+_vm._s(data.item.job_name))])],1)]}},{key:"cell(annonce)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.type_offers)+" ")])]}},{key:"cell(format)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.format)+" ")])]}},{key:"cell(statut)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":("light-" + (_vm.resolveStatusVariant(data.item.status)))}},[_vm._v(" "+_vm._s(data.item.status)+" ")])]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalFlows,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }