import { ref, watch, computed } from "@vue/composition-api";
// @ts-ignore
import store from "@/store";

import * as Api from "../../../axios-generated/backoffice/";

// Notification
import { useToast } from "vue-toastification/composition";
// @ts-ignore
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useFlowsList() {
  // Use toast
  const toast = useToast();

  const refFlowListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "flux", sortable: true },
    { key: "job_name", sortable: true },
    { key: "annonce", sortable: true },
    { key: "format", sortable: true },
    { key: "statut", sortable: true },
  ];
  const perPage = ref(10);
  const totalFlows = ref(0);
  const currentPage = ref(1);
  const sortBy = ref("flux");
  const isSortDirDesc = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refFlowListTable.value
      ? refFlowListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFlows.value,
    };
  });

  const refetchData = () => {
    refFlowListTable.value.refresh();
  };

  const filterSortBy = (sortByParam) => {
    switch (sortByParam) {
      case "flux":
        return "name";
      case "job_name":
        return "job_name";
      case "annonce":
        return "type_offers";
      case "format":
        return "format";
      case "statut":
        return "status";
      default:
        return "uuid";
    }
  };

  watch([currentPage, perPage], () => {
    refetchData();
  });

  const fetchFlows = (ctx, callback) => {
    const sortByFiltered = filterSortBy(sortBy.value);
    store
      .dispatch("app-flow/fetchFlows", {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortByFiltered,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const flows = response.data.flows;
        totalFlows.value = response.data.count;
        callback(flows);
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching flows' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = (status) => {
    if (status === Api.FlowStatusEnum.Disabled) return "danger";
    if (status === Api.FlowStatusEnum.Suspended) return "warning";
    if (status === Api.FlowStatusEnum.Created) return "info";
    if (status === Api.FlowStatusEnum.Enabled) return "success";
    return "primary";
  };

  return {
    fetchFlows,
    tableColumns,
    perPage,
    currentPage,
    totalFlows,
    dataMeta,
    sortBy,
    isSortDirDesc,
    refFlowListTable,

    resolveStatusVariant,

    refetchData,
  };
}
