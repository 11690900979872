<template>
  <section id="flow-list">
    <b-row class="mt-1">
      <b-col class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :to="{ name: 'flow-create' }"
        >
          <feather-icon icon="PlusIcon" size="15" class="mr-50" />
          <span>Créer un Flux</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row class="match-height mt-2">
      <b-col>
        <flow-list-statistics />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col>
        <flow-list-array-view />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { BRow, BCol, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FlowListStatistics from "./FlowListStatistics.vue";
import FlowListArrayView from "@/views/flows/flow-list/FlowListArrayView.vue";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import flowStoreModule from "../flowStoreModule";

export default {
  components: {
    BButton,
    BRow,
    BCol,

    FlowListStatistics,
    FlowListArrayView,
  },
  mixins: [PageBoxed],
  directives: {
    Ripple,
  },
  setup() {
    const FLOW_APP_STORE_MODULE_NAME = "app-flow";

    // Register module
    if (!store.hasModule(FLOW_APP_STORE_MODULE_NAME))
      store.registerModule(FLOW_APP_STORE_MODULE_NAME, flowStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FLOW_APP_STORE_MODULE_NAME))
        store.unregisterModule(FLOW_APP_STORE_MODULE_NAME);
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
