<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in data"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                :variant="item.color"
                v-b-tooltip.hover.v-primary
                :title="item.jobs"
              >
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.$store
      .dispatch("app-flow/findFlowsStatistics")
      .then((response) => {
        this.data = [
          {
            icon: "TrendingUpIcon",
            color: "light-primary",
            title: response.data.nb_ads,
            subtitle: "Annonces",
          },
          {
            icon: "CompassIcon",
            color: "light-primary",
            title: response.data.nb_flows + "/" + response.data.nb_flows_total,
            subtitle: "Flux actifs",
          },
          {
            icon: "CheckCircleIcon",
            color: "light-success",
            title:
              response.data.jobs_ok !== null ? response.data.jobs_ok.length : 0,
            subtitle: "Jobs valides",
            jobs:
              response.data.jobs_ok !== null && response.data.jobs_ok.length > 0
                ? response.data.jobs_ok.join(" / ")
                : "0 jobs",
          },
          {
            icon: "AlertCircleIcon",
            color: "light-danger",
            title:
              response.data.jobs_error !== null
                ? response.data.jobs_error.length
                : 0,
            subtitle: "Jobs en erreur",
            jobs:
              response.data.jobs_error !== null &&
              response.data.jobs_error.length > 0
                ? response.data.jobs_error.join(" / ")
                : "0 jobs",
          },
        ];
      })
      .catch(() => {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Impossible de récupérer les statistiques",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  },
};
</script>
